var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "resume-merge-log" },
    [
      _c("div", { staticClass: "resume-merge-log-top" }, [
        _c(
          "div",
          { staticClass: "link" },
          [
            _c("router-link", { attrs: { to: "/resumeMerge" } }, [
              _vm._v("返回"),
            ]),
          ],
          1
        ),
        _vm._m(0),
      ]),
      _c(
        "div",
        {
          staticClass: "resume-merge-log-main",
          on: { scroll: _vm.handleScroll },
        },
        [
          _vm.logListFetched && _vm.logList.length == 0
            ? _c("div", { staticClass: "empty-box" }, [
                _c("span", { staticClass: "empty-img" }),
                _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
              ])
            : _vm._e(),
          _c(
            "el-timeline",
            _vm._l(_vm.logList, function (log, index) {
              return _c(
                "el-timeline-item",
                { key: index, attrs: { color: "#38bc9d" } },
                [
                  _c(
                    "div",
                    { staticClass: "log-item" },
                    [
                      _c("h3", [
                        _vm._v(_vm._s(log.creator) + " "),
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("timeFormatter")(log.created))),
                        ]),
                      ]),
                      _c("p", [_vm._v("将")]),
                      _vm._l(log.mergeDetails, function (detail) {
                        return [
                          detail.category == 0
                            ? _c("p", { key: detail.tbdResumeId }, [
                                _c("span", [
                                  _vm._v(
                                    "简历ID：" + _vm._s(detail.candidateId)
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    "(TBD ID：" +
                                      _vm._s(detail.tbdResumeId) +
                                      ")"
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    "寻访 " +
                                      _vm._s(detail.inquiryLogCount) +
                                      "/推荐 " +
                                      _vm._s(detail.recommendationCount)
                                  ),
                                ]),
                                _c("i", {
                                  staticClass: "log-item-icon",
                                  class: detail.show
                                    ? "el-icon-arrow-down"
                                    : "el-icon-arrow-right",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toogleCompanyLog(detail)
                                    },
                                  },
                                }),
                              ])
                            : _vm._e(),
                          detail.category == 0 && detail.show
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: detail.loading,
                                      expression: "detail.loading",
                                    },
                                  ],
                                  key: "detail-" + detail.tbdResumeId,
                                  staticClass: "log-detail-container",
                                },
                                [
                                  _c(
                                    "el-tabs",
                                    {
                                      staticClass: "log-detail-tab",
                                      attrs: { type: "card" },
                                      on: {
                                        "tab-click": () =>
                                          _vm.handleDetailTabClick(detail),
                                      },
                                      model: {
                                        value: detail.activeTab,
                                        callback: function ($$v) {
                                          _vm.$set(detail, "activeTab", $$v)
                                        },
                                        expression: "detail.activeTab",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-tab-pane",
                                        {
                                          attrs: {
                                            label: "全部日志",
                                            name: "defaultLog",
                                          },
                                        },
                                        [_vm._v("全部日志")]
                                      ),
                                      _c(
                                        "el-tab-pane",
                                        {
                                          attrs: {
                                            label: "归属权变更日志",
                                            name: "changeBelongerLog",
                                          },
                                        },
                                        [_vm._v("归属权变更日志")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-timeline",
                                    _vm._l(
                                      detail.activeLogItems,
                                      function (item, index) {
                                        return _c(
                                          "el-timeline-item",
                                          {
                                            key: index,
                                            attrs: { color: "#38bc9d" },
                                          },
                                          [
                                            item.isHistorical
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "timeline-item-info",
                                                  },
                                                  [
                                                    _c("p", [
                                                      _c("span", {
                                                        staticClass:
                                                          "log-owner",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            item.creatorRealName +
                                                              "@" +
                                                              item.creatorNickName
                                                          ),
                                                        },
                                                      }),
                                                      _c("span", {
                                                        staticClass:
                                                          "text-grey",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            item.created
                                                          ),
                                                        },
                                                      }),
                                                    ]),
                                                    item.type !== 2 &&
                                                    item.type !== 16
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "log-detail",
                                                          },
                                                          [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    item.content
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    item.type === 4
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "log-detail",
                                                          },
                                                          [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    item.content
                                                                  ),
                                                              },
                                                            }),
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-paperclip",
                                                            }),
                                                            _c("a", {
                                                              staticClass:
                                                                "attachment",
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    item.paramOne
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    item.type === 10
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "log-detail",
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "覆盖更新简历由"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-primary",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onResumeVersionClick(
                                                                        item,
                                                                        detail
                                                                          .activeLogItems[
                                                                          index +
                                                                            1
                                                                        ],
                                                                        0
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " 历史版本 "
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v("更改为"),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-primary",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onResumeVersionClick(
                                                                        item,
                                                                        detail
                                                                          .activeLogItems[
                                                                          index +
                                                                            1
                                                                        ],
                                                                        1
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " 新版本 "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._l(
                                                      item.items,
                                                      function (param, index) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "log-detail",
                                                          },
                                                          [
                                                            param.historicalType !==
                                                              4 &&
                                                            param.historicalType !==
                                                              10
                                                              ? _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "log-detail",
                                                                  },
                                                                  [
                                                                    _c("span", {
                                                                      domProps:
                                                                        {
                                                                          innerHTML:
                                                                            _vm._s(
                                                                              param.content
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            param.historicalType ===
                                                            4
                                                              ? _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "log-detail",
                                                                  },
                                                                  [
                                                                    _c("span", {
                                                                      domProps:
                                                                        {
                                                                          innerHTML:
                                                                            _vm._s(
                                                                              param.content
                                                                            ),
                                                                        },
                                                                    }),
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fa fa-paperclip",
                                                                    }),
                                                                    _c("a", {
                                                                      staticClass:
                                                                        "attachment",
                                                                      domProps:
                                                                        {
                                                                          textContent:
                                                                            _vm._s(
                                                                              param.paramOne
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            param.historicalType ===
                                                              10 &&
                                                            param.isStandardResume !==
                                                              true
                                                              ? _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "log-detail per-text",
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "覆盖更新简历由"
                                                                      ),
                                                                    ]),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-primary",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.onResumeVersionClick(
                                                                                param,
                                                                                detail
                                                                                  .activeLogItems[
                                                                                  index +
                                                                                    1
                                                                                ],
                                                                                0
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " 历史版本 "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "更改为"
                                                                      ),
                                                                    ]),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-primary",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.onResumeVersionClick(
                                                                                param,
                                                                                detail
                                                                                  .activeLogItems[
                                                                                  index +
                                                                                    1
                                                                                ],
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " 新版本 "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            !item.isHistorical &&
                                            _vm.operationLogContent(
                                              item,
                                              item.type
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "timeline-item-info",
                                                  },
                                                  [
                                                    _c("p", [
                                                      _c("span", {
                                                        staticClass:
                                                          "log-owner",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            item.creatorRealName +
                                                              "@" +
                                                              item.creatorNickName
                                                          ),
                                                        },
                                                      }),
                                                      _c("span", {
                                                        staticClass:
                                                          "text-grey",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            item.created
                                                          ),
                                                        },
                                                      }),
                                                    ]),
                                                    [
                                                      item.type !== 2 &&
                                                      item.type !== 21
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "log-detail",
                                                            },
                                                            [
                                                              _c("span", {
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      _vm.operationLogContent(
                                                                        item,
                                                                        item.type
                                                                      )
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      item.type === 2
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "log-detail",
                                                            },
                                                            [
                                                              item.content
                                                                .editLog
                                                                ? _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "log-detail",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .editType[
                                                                                item
                                                                                  .content
                                                                                  .editLog
                                                                                  .editType
                                                                              ]
                                                                            ) +
                                                                              "，完整度变化：" +
                                                                              _vm._s(
                                                                                item
                                                                                  .content
                                                                                  .editLog
                                                                                  .oldCompletion
                                                                              ) +
                                                                              " => " +
                                                                              _vm._s(
                                                                                item
                                                                                  .content
                                                                                  .editLog
                                                                                  .newCompletion
                                                                              ) +
                                                                              "，编辑后简历类型：" +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .resumeTypeHashMap[
                                                                                  item
                                                                                    .content
                                                                                    .editLog
                                                                                    .resumeType
                                                                                ]
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              item.content
                                                                .contentLog
                                                                ? _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "log-detail",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "覆盖更新简历由"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-primary",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.onNewResumeVersionClick(
                                                                                  item
                                                                                    .content
                                                                                    .contentLog
                                                                                    .oldContent,
                                                                                  item
                                                                                    .content
                                                                                    .contentLog
                                                                                    .oldStandardContent,
                                                                                  0
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " 历史版本 "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "更改为"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-primary",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.onNewResumeVersionClick(
                                                                                  item
                                                                                    .content
                                                                                    .contentLog
                                                                                    .newContent,
                                                                                  item
                                                                                    .content
                                                                                    .contentLog
                                                                                    .newStandardContent,
                                                                                  1
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " 新版本 "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      item
                                                                        .content
                                                                        .editLog &&
                                                                      item
                                                                        .content
                                                                        .editLog
                                                                        .isUploadResume
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "R"
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._l(
                                                                item.content
                                                                  .fieldLogs,
                                                                function (
                                                                  param,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "p",
                                                                    {
                                                                      key: index,
                                                                      staticClass:
                                                                        "log-detail",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .fildNameHashMap[
                                                                                param
                                                                                  .fieldName
                                                                              ]
                                                                            ) +
                                                                              "由 " +
                                                                              _vm._s(
                                                                                param.oldValue
                                                                              ) +
                                                                              " 更改为 " +
                                                                              _vm._s(
                                                                                param.newValue
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e(),
                                                      item.type === 21
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "log-detail",
                                                            },
                                                            [
                                                              item.content
                                                                .current
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.creatorRealName
                                                                      ) +
                                                                        "修改归属权，当前归属者由【" +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .current
                                                                            .oldBelonger
                                                                        ) +
                                                                        "】变为【" +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .current
                                                                            .newBelonger
                                                                        ) +
                                                                        "】，生效日期为" +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .current
                                                                            .belongerDate
                                                                        ) +
                                                                        "，简历类型为" +
                                                                        _vm._s(
                                                                          _vm
                                                                            .resumeTypeHashMap[
                                                                            item
                                                                              .content
                                                                              .current
                                                                              .resumeType
                                                                          ]
                                                                        ) +
                                                                        "；"
                                                                    ),
                                                                    _c("br"),
                                                                  ])
                                                                : _vm._e(),
                                                              item.content
                                                                .history
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "历史归属者由【" +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .history
                                                                            .oldBelonger
                                                                        ) +
                                                                        "】变为【" +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .history
                                                                            .newBelonger
                                                                        ) +
                                                                        "】，生效日期为" +
                                                                        _vm._s(
                                                                          item
                                                                            .content
                                                                            .history
                                                                            .belongerDate
                                                                        ) +
                                                                        "，简历类型为" +
                                                                        _vm._s(
                                                                          _vm
                                                                            .resumeTypeHashMap[
                                                                            item
                                                                              .content
                                                                              .history
                                                                              .resumeType
                                                                          ]
                                                                        ) +
                                                                        "。"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      }),
                      _c("p", [_vm._v("合并到")]),
                      _vm._l(log.mergeDetails, function (detail) {
                        return detail.category == 1
                          ? _c("p", { key: detail.tbdResumeId }, [
                              _c("span", [
                                _vm._v("简历ID：" + _vm._s(detail.candidateId)),
                              ]),
                              _c("span", [
                                _vm._v(
                                  "(TBD ID：" + _vm._s(detail.tbdResumeId) + ")"
                                ),
                              ]),
                              _c("span", [
                                _vm._v(
                                  "寻访 " +
                                    _vm._s(detail.inquiryLogCount) +
                                    "/推荐 " +
                                    _vm._s(detail.recommendationCount)
                                ),
                              ]),
                            ])
                          : _vm._e()
                      }),
                    ],
                    2
                  ),
                ]
              )
            }),
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.logListLoading,
                  expression: "logListLoading",
                },
              ],
              staticClass: "loading-box",
            },
            [_vm._v(_vm._s(_vm.hasNoMore ? "没有更多了~" : ""))]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "resume-merge-log-footer" }, [
        _vm._v("\n        猎必得 liebide.com\n    "),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "resume-version-dialog",
            title: _vm.resumeVersionCaption,
            visible: _vm.resumeVersionDialogVisible,
            width: "480px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              model: {
                value: _vm.tabFirst,
                callback: function ($$v) {
                  _vm.tabFirst = $$v
                },
                expression: "tabFirst",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: `${_vm.resumeVersionCaption}简历原文`,
                    name: "first",
                  },
                },
                [_vm._v(_vm._s(_vm.resumeVersionContent.now))]
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: `${_vm.resumeVersionCaption}标准简历`,
                    name: "second",
                  },
                },
                [_vm._v(_vm._s(_vm.resumeVersionContent.standard))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "title" }, [_c("span"), _vm._v("合并日志")])
  },
]
render._withStripped = true

export { render, staticRenderFns }