<template>
    <div class="resume-merge-log">
        <div class="resume-merge-log-top">
            <div class="link">
                <router-link to="/resumeMerge">返回</router-link>
            </div>
            <h3 class="title"><span></span>合并日志</h3>
        </div>
        <div class="resume-merge-log-main" @scroll="handleScroll">
            <div v-if="logListFetched && logList.length == 0" class="empty-box">
                <span class="empty-img"></span>
                <p class="empty-data">暂无数据</p>
            </div>

            <el-timeline>
                <el-timeline-item
                    v-for="(log, index) in logList"
                    :key="index"
                    color="#38bc9d"
                >
                    <div class="log-item">
                        <h3>{{log.creator}} <span>{{log.created | timeFormatter}}</span></h3>
                        <p>将</p>
                        <template v-for="detail in log.mergeDetails">
                            <p :key="detail.tbdResumeId" v-if="detail.category == 0">
                                <span>简历ID：{{detail.candidateId}}</span>
                                <span>(TBD ID：{{detail.tbdResumeId}})</span>
                                <span>寻访 {{detail.inquiryLogCount}}/推荐 {{detail.recommendationCount}}</span>
                                <i class="log-item-icon" :class="detail.show ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" @click="toogleCompanyLog(detail)"></i>
                            </p>
                            <div 
                                class="log-detail-container"
                                :key="'detail-' + detail.tbdResumeId" 
                                v-if="detail.category == 0 && detail.show" 
                                v-loading="detail.loading"
                            >
                                <el-tabs v-model="detail.activeTab" type="card" class="log-detail-tab" @tab-click="() => handleDetailTabClick(detail)">
                                    <el-tab-pane label="全部日志" name="defaultLog">全部日志</el-tab-pane>
                                    <el-tab-pane label="归属权变更日志" name="changeBelongerLog">归属权变更日志</el-tab-pane>
                                </el-tabs>
                                <el-timeline>
                                    <el-timeline-item
                                        v-for="(item, index) in detail.activeLogItems"
                                        :key="index"
                                        color="#38bc9d"
                                    >
                                        <div class="timeline-item-info" v-if="item.isHistorical">
                                            <p>
                                                <span class="log-owner" v-text="item.creatorRealName + '@' + item.creatorNickName"></span>
                                                <span class="text-grey" v-text="item.created"></span>
                                            </p>
                                            <p v-if="item.type !== 2 && item.type !== 16" class="log-detail">
                                                <span v-html="item.content"></span>
                                            </p>
                                            <p v-if="item.type === 4" class="log-detail">
                                                <span v-html="item.content"></span>
                                                <i class="fa fa-paperclip"></i>
                                                <a class="attachment" v-text="item.paramOne"></a>
                                            </p>
                                            <p v-if="item.type === 10" class="log-detail">
                                                <span>覆盖更新简历由</span>
                                                <span class="text-primary" @click="onResumeVersionClick(item, detail.activeLogItems[index + 1],0)"> 历史版本 </span>
                                                <span>更改为</span>
                                                <span class="text-primary" @click="onResumeVersionClick(item, detail.activeLogItems[index + 1], 1)"> 新版本 </span>
                                            </p>

                                            <template>
                                                <div class="log-detail" v-for="(param, index) in item.items" :key="index">
                                                    <p v-if="param.historicalType !== 4 && param.historicalType !== 10" class="log-detail">
                                                        <span v-html="param.content"></span>
                                                    </p>
                                                    <p v-if="param.historicalType === 4" class="log-detail">
                                                        <span v-html="param.content"></span>
                                                        <i class="fa fa-paperclip"></i>
                                                        <a class="attachment" v-text="param.paramOne"></a>
                                                    </p>
                                                    <p v-if="param.historicalType === 10 && param.isStandardResume !== true" class="log-detail per-text">
                                                        <span>覆盖更新简历由</span>
                                                        <span class="text-primary" @click="onResumeVersionClick(param, detail.activeLogItems[index + 1],0)"> 历史版本 </span>
                                                        <span>更改为</span>
                                                        <span class="text-primary" @click="onResumeVersionClick(param, detail.activeLogItems[index + 1], 1)"> 新版本 </span>
                                                    </p>
                                                </div>
                                            </template>

                                        </div>

                                        <div class="timeline-item-info" v-if="!item.isHistorical && operationLogContent(item, item.type)">
                                            <p>
                                                <span class="log-owner" v-text="item.creatorRealName + '@' + item.creatorNickName"></span>
                                                <span class="text-grey" v-text="item.created"></span>
                                            </p>

                                            <template>
                                                <p class="log-detail" v-if="item.type !== 2 && item.type !== 21">
                                                    <span v-html="operationLogContent(item,item.type)"></span>
                                                </p>

                                                <span class="log-detail" v-if="item.type === 2">
                                                    <p class="log-detail" v-if="item.content.editLog">
                                                        <span>{{ editType[item.content.editLog.editType] }}，完整度变化：{{item.content.editLog.oldCompletion}} => {{item.content.editLog.newCompletion}}，编辑后简历类型：{{resumeTypeHashMap[item.content.editLog.resumeType]}}</span>
                                                    </p>
                                                    <p class="log-detail" v-if="item.content.contentLog">
                                                        <span>覆盖更新简历由</span>
                                                        <span class="text-primary" @click="onNewResumeVersionClick(item.content.contentLog.oldContent, item.content.contentLog.oldStandardContent, 0)"> 历史版本 </span>
                                                        <span>更改为</span>
                                                        <span class="text-primary" @click="onNewResumeVersionClick(item.content.contentLog.newContent, item.content.contentLog.newStandardContent, 1)"> 新版本 </span>
                                                        <span v-if="item.content.editLog && item.content.editLog.isUploadResume">R</span>
                                                    </p>
                                                    <p class="log-detail" v-for="(param,index) in item.content.fieldLogs" :key="index">
                                                        <span>{{ fildNameHashMap[param.fieldName] }}由 {{ param.oldValue }} 更改为 {{ param.newValue }}</span>
                                                    </p>
                                                </span>

                                                <p class="log-detail" v-if="item.type === 21">
                                                    <span v-if="item.content.current">{{ item.creatorRealName }}修改归属权，当前归属者由【{{ item.content.current.oldBelonger }}】变为【{{item.content.current.newBelonger}}】，生效日期为{{ item.content.current.belongerDate }}，简历类型为{{ resumeTypeHashMap[item.content.current.resumeType] }}；<br></span>
                                                    <span v-if="item.content.history">历史归属者由【{{ item.content.history.oldBelonger }}】变为【{{ item.content.history.newBelonger }}】，生效日期为{{ item.content.history.belongerDate }}，简历类型为{{ resumeTypeHashMap[item.content.history.resumeType] }}。</span>
                                                </p>
                                            </template>
                                        </div>
                                    </el-timeline-item>
                                </el-timeline>
                            </div>
                        </template>
                        <p>合并到</p>
                        <p v-for="detail in log.mergeDetails" :key="detail.tbdResumeId" v-if="detail.category == 1">
                            <span>简历ID：{{detail.candidateId}}</span>
                            <span>(TBD ID：{{detail.tbdResumeId}})</span>
                            <span>寻访 {{detail.inquiryLogCount}}/推荐 {{detail.recommendationCount}}</span>
                        </p>
                    </div>
                </el-timeline-item>
            </el-timeline>

            <div class="loading-box" v-loading="logListLoading">{{hasNoMore ? '没有更多了~' : ''}}</div>

        </div>
        
        <div class="resume-merge-log-footer">
            猎必得 liebide.com
        </div>

        <el-dialog
            custom-class="resume-version-dialog"
            :title="resumeVersionCaption"
            :visible="resumeVersionDialogVisible"
            width="480px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
        >
            <el-tabs v-model="tabFirst" type="border-card">
                <el-tab-pane :label="`${resumeVersionCaption}简历原文`" name="first">{{ resumeVersionContent.now }}</el-tab-pane>
                <el-tab-pane :label="`${resumeVersionCaption}标准简历`" name="second">{{ resumeVersionContent.standard }}</el-tab-pane>
            </el-tabs>
        </el-dialog>
    </div>
</template>

<script>
import resumeMergeService from '#/js/service/resumeMerge.js';
import moment from 'moment';
export default {
    name: 'resumeMerge',
    components: {
    },
    data() {
        return {
            logListFetched: false,
            logListLoading: true,
            start: 0,
            take: 10,
            canLoadMore: true,
            hasNoMore: false,
            logList: [],
            resumeVersionDialogVisible: false,
            resumeVersionCaption: '',
            tabFirst: 'first',
            resumeVersionContent: {
                standard: '',
                now: ''
            },
            editType: {
                0: '普通编辑',
                1: '关键信息编辑',
                2: '完整简历编辑'
            },
            belongResonMap: {
                0: '创建简历',
                1: '重大更新',
                2: '完整简历更新',
                3: '人工修改'
            },
            resumeTypeHashMap: {
                0: '普通简历',
                1: 'Profile简历',
                2: '完整简历'
            },
            fildNameHashMap: {
                realName: '姓名',
                mobile: '手机号',
                email: '邮箱',
                location: '城市',
                title: '职位',
                company: '公司',
                yearOfExperience: '工作年限',
                school: '毕业院校',
                degree: '学历',
                major: '专业',
                gender: '性别',
            },
            
        }
    },
    computed: {
        
    },
    filters: {
        timeFormatter(time) {
            return time ? moment(time).format('YYYY-MM-DD HH:mm:ss') : '';
        }
    },
    mounted() {
        this.getMergeLogList();
    },
    methods: {
        getMergeLogList() {
            this.logListLoading = true;
            this.canLoadMore = false;
            resumeMergeService.getMergeLog({
                start: this.start,
                take: this.take
            }).then(res => {
                if(res.count == 0) {
                    this.hasNoMore = true;
                }else {
                    res.list.forEach(item => {
                        if(item.mergeDetails && item.mergeDetails.length > 0) {
                            item.mergeDetails.forEach(detail => {
                                detail.show = false;
                                detail.loading = false;
                                detail.operationLogFetched = false;
                                detail.changeBelongerLogItems = [];
                                detail.defaultLogItems = [];
                                detail.activeLogItems = [];
                                detail.activeTab = 'defaultLog';
                            })
                        }
                    })
                    this.logList = this.logList.concat(res.list || []);
                    this.start += 10;
                    if(res.count < this.take) {
                        this.hasNoMore = true;
                    }
                }
                this.logListFetched = true;
                this.$nextTick(() => {
                    this.canLoadMore = true;
                })
            }).finally(() =>{
                this.logListLoading = false;
            })
        },
        handleScroll(e) {
            if(!this.canLoadMore || this.hasNoMore) {
                return false;
            }
            let container = e.target;
            if((container.scrollHeight - container.scrollTop - container.offsetHeight) <= 50) {
                this.logListLoading = true;
                this.getMergeLogList();
            }
        },
        toogleCompanyLog(detail) {
            detail.show = !detail.show;
            if(detail.show && !detail.operationLogFetched) {
                detail.loading = true;
    
                resumeMergeService.getOperateLog(detail.ossFileId)
                    .then(res => {
                        detail.operationLogFetched = true;
                        detail.changeBelongerLogItems = res.changeBelongerLogItems;
                        detail.defaultLogItems = res.defaultLogItems;
                        detail.activeLogItems = detail.defaultLogItems;
                    }).finally(() =>{
                        detail.loading = false;  
                    })
            }
        },
        operationLogContent (item, type){
            let content,status=false;
            if(item.content.constructor === String) {
                content = item.content = item.content ? JSON.parse(item.content) : {};
            } else {
                status = true;
                content = item.content;
            }
            switch (type) {
                case 1:
                    return `添加了 ${content.realName}`;
                case 11:
                    return `导入了 ${content.realName} 至企业库`;
                case 12:
                    return content.type === 1 ? `查看手机号` : `查看邮箱`;
                case 13:
                    return `推荐至 ${content.customerName} 的 ${content.jobName}`;
                case 16:
                    if(content.type === 1 && item.objectId) {
                        return `${content.type === 0 ? '' : '在推荐记录中'} 上传人才附件<span class="fa fa-paperclip"><a class="text-primary" href="${location.origin}/Data/File/${item.objectId}" class="document-anchor">${content.fileName}（${content.fileSize}KB）</a>`
                    }
                    return `${content.type === 0 ? '' : '在推荐记录中'} 上传人才附件<span class="fa fa-paperclip"></span><span class="text-primary" style="cursor: default;">${content.fileName}（${content.fileSize}KB）</span>`;
                case 20:
                    return `${content.newBelonger}${this.belongResonMap[content.belongReson]}，当前归属者为【${content.newBelonger}】，简历类型为${this.resumeTypeHashMap[content.resumeType]}，历史归属者为【${content.oldBelonger || '无'}】。`;
                case 21:
                    return content.current || content.history;
                case 22:
                    let html = '';
                    content.map((item,index) => {
                        html = `${html}${index + 1}.【${item.recommendUser}】推荐到【${item.jobName}】的分成比例由【权益者-${item.oldProportion.firstEquityUser || '无'}：${item.oldProportion.firstEquityProportion}%，权益者-${item.oldProportion.secondEquityUser || '无'}：${item.oldProportion.secondEquityProportion}%，推荐人-${item.recommendUser}：${item.oldProportion.recommendProportion}%】变为【权益者-${item.newProportion.firstEquityUser}：${item.newProportion.firstEquityProportion}%，权益者-${item.newProportion.secondEquityUser}：${item.newProportion.secondEquityProportion}%，推荐人-${item.recommendUser}：${item.newProportion.recommendProportion}%】<br>`
                    })
                    return html;
                case 23:
                    return content.type === 1 ? `标记手机号码失效` : `标记邮箱失效`;
                case 2:
                    if(content.fieldLogs && !status){
                        content.fieldLogs.sort((item1,item2) => {
                            if(keyIndexMap[item1.fieldName] < keyIndexMap[item2.fieldName]){
                                status = true;
                                return  -1;
                            }
                            return 1;
                        })
                    }
                case 15:
                    return '请求分享';
                case 28:
                    return content.isCoverSuccess ? "上传重复简历-已覆盖" : "上传重复简历";
                case 29:
                    return '简历合并';
                default:
                    return '';
            }
            return item.content = content;
        },
        onResumeVersionClick(vm,vm2,type) {
            if(!vm2) {
                vm2 = {
                    paramTwo: '',
                    paramOne: '',
                }
            }
            if(type === 1){
                this.resumeVersionCaption = '新版本';
                this.resumeVersionContent.now = vm.paramTwo;
                this.resumeVersionContent.standard = vm2.paramTwo;
            }else{
                this.resumeVersionCaption = '历史版本';
                this.resumeVersionContent.now = vm.paramOne;
                this.resumeVersionContent.standard = vm2.paramOne;
            }
            this.resumeVersionDialogVisible = true;
        },
        handleDetailTabClick(detail) {
            if(detail.activeTab == 'defaultLog') {
                detail.activeLogItems = detail.defaultLogItems;
            }else {
                detail.activeLogItems = detail.changeBelongerLogItems;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .resume-merge-log {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
        background:url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;

        &-top {
            position: relative;
            padding: 0 50px;

            .link {
                position: absolute;
                top: 20px;
                right: 50px;
                font-size: 14px;
                color: #666;

                a:hover {
                    color: $primary;
                }
            }

            .title {
                padding-top: 20px;
                padding-bottom: 14px;
                margin: 0;
                font-size: 18px;
                color: #333;
                line-height: 26px;

                span {
                    display: inline-block;
                    width: 10px;
                    height: 26px;
                    background-color: $primary;
                    margin-right: 10px;
                    vertical-align: top;
                }
            }
        }

        &-main {
            position: relative;
            flex: 1;
            flex-grow: 1;
            padding: 10px 50px 0;
            overflow-y: scroll;
        }

        .empty-box {
            text-align: center;

            .empty-img {
                display: inline-block;
                background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                width: 240px;
                height: 228px;
            }

            .empty-data {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 0px;
            }
        }

        .log-item {
            margin-bottom: 5px;

            > h3 {
                font-size: 14px;
                color: #4a4a4a;
                margin-bottom: 7px;

                span {
                    color: #3f3f3f;
                    margin-left: 10px;
                }
            }

            > p {
                margin: 0;
                line-height: 23px;

                span {
                    margin-right: 10px;
                }
            }

            &-icon {
                font-weight: bold;
                color: #000;
                font-size: 14px;
                cursor: pointer;
            }

            .log-detail-container {
                min-height: 45px;
                margin: 10px 0;

                .log-detail-tab /deep/ {
                    width: 237px;

                    .el-tabs__content {
                        display: none;
                    }
                }

                .timeline-item-info{
                    font-size: 14px;
                    overflow: hidden;

                    p {
                        margin-bottom: 4px;
                    }

                    .log-owner {
                        color: #333;
                        margin-right: 5px;
                    }

                    .text-grey {
                        color:#9b9b9b;
                    }

                    .log-detail {
                        color: #666;

                        i {
                            display: inline-block;
                            padding: 0 2px 0 4px;
                        }
                        .attachment{
                            color: #18bd9d;
                        }
                        .text-primary{
                            &:hover{
                                cursor: pointer;
                            }
                        }
                    }

                }
            }
        }

        /deep/ .el-timeline-item {
            .el-timeline-item__tail {
                display: block;
                border-color: $primary;
            }

            &:last-child::after {
                content: '';
                position: absolute;
                top: 0;
                left: 4px;
                height: 100%;
                border-left: 2px solid $primary;
            }
        }

        .loading-box {
            height: 45px;
            line-height: 45px;
            text-align: center;
        }

        &-footer {
            height:45px;
            line-height: 45px;
            text-align: center;
            font-size: 12px;
            color: #999999;
        }
    }
</style>